import { createClient } from "@/utils/supabase/client";
import { User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";

export const useAuth = () => {
  const supabase = createClient();
  const [user, setUser] = useState<User|null>();
  const [isLoading, setIsLoading] = useState(true);

  const refresh = async () => {
    const {data: {user}} = await supabase.auth.refreshSession();

    setUser(user);
  }

  useEffect(() => {
    const {data: {subscription}} = supabase.auth.onAuthStateChange((event, session) => {
      setTimeout(async () => {
        // await on other Supabase function here
        // this runs right after the callback has finished
        setUser(session?.user || null);

        setIsLoading(false);
      }, 0)
    })

    return () => subscription.unsubscribe()
  }, []);

  return {
    user,
    refresh,
    isLoading
  }
}
