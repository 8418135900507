"use client";

import { Tables } from "@/types/database";
import { create } from "zustand";
import { getCurrentUserProfile } from "@/actions/users";

type UserProfile = Tables<"users">;

interface State {
  loading: boolean,
  profile: UserProfile|null,
}

interface Store extends State {
  refresh(): Promise<UserProfile|null>,
}

const initialState: State = {
  loading: false,
  profile: null,
}

const useProfile = create<Store>((set, get) => {
  const setIsLoading = (loading: boolean) => {
    set(state => ({
      ...state,
      loading
    }));
  }

  const setProfile = (profile: UserProfile|null) => {
    set(state => ({
      ...state,
      profile
    }));
  }

  return {
    ...initialState,
    refresh: async () => {
      if (get().loading) {
        return;
      }

      setIsLoading(true);

      const profile = await getCurrentUserProfile();

      setProfile(profile);

      setIsLoading(false);

      return profile;
    }
  } as State & Store
});

export default useProfile;