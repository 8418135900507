"use client";

import { useAuth } from "@/hooks/useAuth";
import { useEffect } from "react";
import useProfile from "@/store/useProfile";

export default function ProfileSubscription () {
  const {user} = useAuth();
  const {profile, loading, refresh} = useProfile();

  useEffect(() => {
    refresh();
  }, [user]);

  return <></>
}
